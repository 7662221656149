import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { SectionItem } from '../common';

const fields = {
	collaboration: 'boolean',
	connectDomains: 'input',
	customFonts: 'boolean',
	customStylesheets: 'boolean',
	editConfig: 'boolean',
	editScript: 'boolean',
	editStyle: 'boolean',
	passParameters: 'boolean',
	popupEmbedding: 'boolean',
	quota: 'input',
	removeBranding: 'boolean',
	selectGoogleFonts: 'boolean',
};

export default function General({ user, userId }) {
	const firestore = useFirestore();

	function onUpdateFeature({ prop, value }) {
		firestore.update(
			{
				collection: 'users',
				doc: userId,
			},
			{
				features: {
					...user.features,
					[prop]: value,
				},
			},
		);
	}

	return (
		<SectionItem
			data={user?.features || {}}
			fields={fields}
			onUpdate={onUpdateFeature}
			title="General"
			canEdit={true}
		/>
	);
}
