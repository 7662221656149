import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Table } from '../common';
import { translate } from '../utils';
import { getFlows } from '../../actions/firestore';
import { searchFlows } from '../../actions/algolia';

const transformFlow = flow => {
	const { title, id, status, lastDeployment } = flow;
	return {
		href: `/flows/${id}`,
		values: [
			{ label: title },
			{ label: id },
			{
				label: translate(status),
				statusColor: status === 'live' ? 'positive' : 'neutral',
			},
			{ label: new Date(lastDeployment).toLocaleString() },
		],
	};
};

export default function FlowList() {
	const dispatch = useDispatch();
	const flows = useSelector(state => state.flows.data);

	useEffect(() => {
		dispatch(getFlows());
	}, [dispatch]);

	const searchForFlow = useCallback(
		debounce(searchQuery => {
			dispatch(searchFlows(searchQuery));
		}, 500),
	);

	return (
		<div className="scroll">
			<Table
				onSearch={searchForFlow}
				hasSearchBar={true}
				searchForIndeces={[0, 1]}
				title="Flows"
				labels={['Title', 'ID', 'Status', 'Last Deployed']}
				rows={flows ? flows.map(transformFlow) : []}
			/>
		</div>
	);
}
