import React, { useRef } from 'react';
import classnames from 'classnames';

export default function Switch({ checked, onChange, disabled }) {
	const checkbox = useRef(null);
	return (
		<div>
			<input
				onClick={e => onChange(e.target.checked)}
				ref={checkbox}
				className="hidden"
				type="checkbox"
				defaultChecked={checked}
				disabled={disabled}
			/>
			<span
				className={classnames('switch', { 'is-disabled': disabled })}
				onClick={() => checkbox.current.click()}></span>
		</div>
	);
}
