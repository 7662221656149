import React from 'react';
import { useSelector } from 'react-redux';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import swal from 'sweetalert';
import { Table, Button } from '../common';
import { translate } from '../utils';

export default function Permissions({ flow, flowId }) {
	const { permissions } = flow;
	const firestore = useFirestore();
	const usersPermission = useSelector(({ firestore: { data } }) => data);
	const { email, uid } = useSelector(state => state.firebase.auth);
	const selfIsInPermissions = Object.keys(permissions).includes(uid);

	useFirestoreConnect(() =>
		Object.keys(permissions).map(uid => ({
			collection: 'users',
			storeAs: uid,
			doc: uid,
		})),
	);

	if (!flow.permissions) return null;

	const newUsersPermission = userId =>
		Object.keys(permissions)
			.filter(uid => uid !== userId)
			// eslint-disable-next-line no-sequences
			.reduce((p, uid) => ((p[uid] = permissions[uid]), p), {});

	function deleteUser(userId) {
		firestore.update(
			{
				collection: 'flows',
				doc: flowId,
			},
			{
				permissions: newUsersPermission(userId),
			},
		);
	}

	function updatePermissions(userId) {
		firestore.update(
			{
				collection: 'flows',
				doc: flowId,
			},
			{
				permissions: {
					...permissions,
					[userId]: 'editor',
				},
			},
		);
	}

	const addUser = async email => {
		const user = await firestore
			.collection('users')
			.where('email', '==', email)
			.limit(1)
			.get()
			.then(snapshot =>
				snapshot.empty
					? null
					: { ...snapshot.docs[0].data(), uid: snapshot.docs[0].id },
			);
		if (user === null) {
			swal('User not in database!');
			return;
		}
		if (Object.keys(permissions).includes(user.uid)) {
			swal('User already in permissions');
			return;
		} else {
			updatePermissions(user.uid);
			swal('User added!');
		}
	};

	const onAddUser = () => {
		swal({
			text: 'Add user',
			content: {
				element: 'input',
				attributes: {
					placeholder: 'Enter email here',
				},
			},
			button: {
				text: 'Add User',
			},
			allowEscapeKey: true,
			allowOutsideClick: true,
		})
			.then(email => {
				if (email) {
					addUser(email);
				} else {
					swal.close();
				}
			})
			.catch(err => {
				if (err) {
					swal('Oh no!', 'Something went wrong :(');
				} else {
					swal.stopLoading();
					swal.close();
				}
			});
	};

	const onAddSelf = () => {
		addUser(email);
	};

	const onRemoveSelf = () => {
		if (!selfIsInPermissions) {
			swal('User not in permissions');
			return;
		}

		deleteUser(uid);
	};

	return (
		<div>
			<div className="permissions">
				<Table
					hasSearchBar={false}
					title="Permissions"
					headerComponent={
						<div
							style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
							<Button
								style={{ marginRight: 20 }}
								label="Add user"
								onClick={onAddUser}
							/>

							{!selfIsInPermissions ? (
								<Button label="Add self" onClick={onAddSelf} />
							) : (
								<Button label="Remove self" onClick={onRemoveSelf} />
							)}
						</div>
					}
					labels={['Email', 'Role', '']}
					rows={
						permissions &&
						Object.keys(permissions).map(uid => {
							return {
								values: [
									{ label: usersPermission[uid]?.email },
									{ label: translate(permissions[uid]) },
									{
										buttonLabel: 'Remove',
										buttonAction: () => deleteUser(uid),
									},
								],
							};
						})
					}
				/>
			</div>
		</div>
	);
}
