const translate = prop => {
	const dict = {
		owner: 'Owner',
		editor: 'Editor',
		live: 'Live',
		draft: 'Draft',
		emailVerified: 'Email verified',
		collaboration: 'Collaboration',
		connectDomains: 'Connect Domains',
		customFonts: 'Custom Fonts',
		customStylesheets: 'Custom Stylesheet',
		editConfig: 'Edit Config',
		editScript: 'Edit JavaScript',
		editStyle: 'Edit CSS',
		passParameters: 'Pass URL Parameters',
		popupEmbedding: 'Popup Embedding',
		quota: 'Quota',
		removeBranding: 'Remove Branding',
		selectGoogleFonts: 'Google Fonts',
		'active-no-stripe': 'Active (no Stripe)',
		active: 'Active',
		'create-no-payment': 'Checkout not complete',
		en_us: 'English',
		de_de: 'German',
		'niro-free': 'Heyflow Free',
		'niro-basic-monthly': 'Niro Basic (legacy) – Monthly',
		'niro-basic-yearly': 'Niro Basic (legacy) – Yearly',
		'niro-pro-monthly': 'Niro Pro (legacy) – Monthly',
		'niro-pro-yearly': 'Niro Pro (legacy) – Yearly',
		'niro-enterprise-monthly': 'Niro Enterprise (legacy) – Monthly',
		'niro-enterprise-yearly': 'Niro Enterprise (legacy) – Yearly',
		'niro-0920-intro-monthly': 'Heyflow Intro – Monthly',
		'niro-0920-intro-yearly': 'Heyflow Intro – Yearly',
		'niro-0920-basic-monthly': 'Heyflow Basic – Monthly',
		'niro-0920-basic-yearly': 'Heyflow Basic – Yearly',
		'niro-0920-pro-monthly': 'Heyflow Pro – Monthly',
		'niro-0920-pro-yearly': 'Heyflow Pro – Yearly',
		'niro-0920-business-monthly': 'Heyflow Business – Monthly',
		'niro-0920-business-yearly': 'Heyflow Business – Yearly',
		asana: 'Asana',
		fbPixel: 'Facebook Pixel',
		googleAnalytics: 'Google Analytics',
		googleAnalytics4: 'Google Analytics 4',
		googleAppEngine: 'Google App Engine',
		googleSheets: 'Google Sheets',
		googleTagManager: 'Google Tag Manager',
		pipedrive: 'Pipedrive',
		legalMonster: 'Legal Monster',
		ses: 'Email',
		slack: 'Slack',
		hubspot: 'HubSpot',
		webhook: 'Webhook',
		zapierV2: 'Zapier',
		beta: 'Beta',
		developer: 'Developer',
		dogfood: 'Dog Food',
		experimental: 'Experimental',
		staging: 'Staging',
		tower: 'Tower',
		displayName: 'Name',
		email: 'Email',
		lang: 'Language',
		stripeCustomerID: 'Stripe Customer ID',
		address: 'Address',
		vatID: 'VAT ID',
		upload: 'Upload',
	};

	return dict[prop] || prop;
};

export default translate;
