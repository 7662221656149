import { GET_FLOW } from '../actions/firestore';

export default (state = {}, action) => {
	state.actionType = action.type;

	switch (action.type) {
		case GET_FLOW:
			return {
				...state,
				status: action.status,
				data: action.data,
				err: action.error,
			};

		default:
			return state;
	}
};
