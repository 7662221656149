import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import NavItem from '../common/NavItem';
import { useHistory } from 'react-router-dom';

const SideBarValues = [
	{
		label: 'Flows',
		href: 'flows',
		icon: 'git-network',
	},
	{
		label: 'Users',
		href: 'users',
		icon: 'people',
	},
];

function SideBarItems({ label, onClick, href, icon }) {
	return (
		<div className="sidebar-items" onClick={onClick}>
			<NavItem label={label} href={href} icon={icon} />
		</div>
	);
}

export default function SideBar() {
	const [option, setOption] = useState('Flows');
	const firebase = useFirebase();
	const history = useHistory();

	const onLogout = async e => {
		if (e) e.preventDefault();
		firebase.logout();
		history.push('/');
	};

	return (
		<div className="sidebar">
			<div>
				{SideBarValues.map(item => (
					<SideBarItems
						key={item.label}
						option={option}
						label={item.label}
						targetOption={item.label}
						onClick={() => setOption(item.label)}
						href={item.href}
						icon={item.icon}
					/>
				))}
			</div>
			<div>
				<div onClick={onLogout}>
					<NavItem label="Logout" icon="log-out" />
				</div>
			</div>
		</div>
	);
}
