import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

function Row({ values, disabled }) {
	return (
		<div className={classnames('table-row', { 'is-disabled': disabled })}>
			{values.map(({ label, statusColor, buttonAction, buttonLabel }, j) => (
				<div key={j} className="table-cell">
					{statusColor && (
						<span
							className={classnames({
								'status-indicator': true,
								'green-2': statusColor === 'positive',
								'gray-600': statusColor === 'neutral',
							})}>
							<ion-icon name="ellipse"></ion-icon>
						</span>
					)}
					{buttonAction && (
						<div
							className="button"
							onClick={() =>
								swal({
									text: 'Are you sure you want to delete user?',
									icon: 'warning',
									buttons: ['Cancel', 'Yes'],
									dangerMode: true,
								})
									.then(value => {
										if (value) {
											buttonAction();
											swal('User deleted');
										}
									})
									.catch(err => {
										if (err) {
											swal('Oh no!', 'Something went wrong :(');
										} else {
											swal.stopLoading();
											swal.close();
										}
									})
							}>
							{buttonLabel}
						</div>
					)}
					{label}
				</div>
			))}
		</div>
	);
}

export default function Table({
	hasSearchBar,
	headerComponent,
	searchForIndeces = [0],
	title,
	labels,
	rows,
	onSearch,
}) {
	const [searchFor, setSearchFor] = useState('');

	return (
		<>
			{hasSearchBar ? (
				<div className="search-bar">
					<h2 className="search-bar-label">{title}</h2>
					<div className="search-bar-container">
						<ion-icon name="search"></ion-icon>
						<input
							type="text"
							placeholder="Search"
							onChange={e => {
								if (typeof onSearch === 'function') onSearch(e.target.value);
								setSearchFor(e.target.value);
							}}
						/>
					</div>
				</div>
			) : (
				<div className="search-bar">
					<h2 className="search-bar-label">{title}</h2>
					{headerComponent}
				</div>
			)}
			<div className="table">
				<div
					className="table-header-row is-disabled"
					style={{ position: 'sticky', top: 100, zIndex: 3 }}>
					{labels.map(item => (
						<span key={item} className="table-cell">
							{item}
						</span>
					))}
				</div>
				<div>
					{rows &&
						rows
							.filter(row =>
								searchFor && searchForIndeces.every(index => row.values[index])
									? searchForIndeces.some(
											index =>
												row.values[index]?.label &&
												row.values[index].label.match(
													new RegExp(searchFor, 'i'),
												) !== null,
									  )
									: true,
							)
							.map((row, i) =>
								row.href ? (
									<Link key={i} to={row.href}>
										<Row values={row.values} />
									</Link>
								) : (
									<Row key={i} values={row.values} disabled={true} />
								),
							)}
				</div>
			</div>
		</>
	);
}
