import React from 'react';
import { NavLink } from 'react-router-dom';

export default function NavItem({ isActive, label, href, icon }) {
	return (
		<NavLink activeClassName="is-active" className="nav-item" to={`/${href}`}>
			<ion-icon name={icon}></ion-icon>
			<span>{label}</span>
		</NavLink>
	);
}
