import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { SectionItem } from '../common';
import { useSelector } from 'react-redux';

const fields = {
	beta: 'boolean',
	developer: 'boolean',
	dogfood: 'boolean',
	experimental: 'boolean',
	staging: 'boolean',
	tower: 'boolean',
};

export default function Modi({ user, userId }) {
	const firestore = useFirestore();
	const { role } = useSelector(({ firebase: { profile } }) => profile);

	function onUpdateFeature({ prop, value }) {
		firestore.update(
			{
				collection: 'users',
				doc: userId,
			},
			{
				modi: {
					...user.modi,
					[prop]: value,
				},
			},
		);
	}

	return (
		<SectionItem
			data={user?.modi || {}}
			fields={fields}
			onUpdate={onUpdateFeature}
			title="Modi"
			canEdit={role === 'admin'}
		/>
	);
}
