import { createStore, applyMiddleware, compose } from 'redux';

import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import promise from 'redux-promise';

import {
	createFirestoreInstance,
	getFirestore,
	reduxFirestore,
} from 'redux-firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import rootReducer from '../reducers';
import firebaseDevelopmentConfig from '../config/firebase.development';
import firebaseProductionConfig from '../config/firebase.production';

const rrfConfig = {
	userProfile: 'users',
	useFirestoreForProfile: true,
};

const startProd =
	process.env.NODE_ENV === 'production' ||
	process.env.REACT_APP_FIREBASE_MODE === 'production';

console.log(
	`Initializing firebase in ${startProd ? 'production' : 'development'} mode`,
);

console.log(process.env.REACT_APP_FIREBASE_MODE);

firebase.initializeApp(
	startProd ? firebaseProductionConfig : firebaseDevelopmentConfig,
);
const firestore = firebase.firestore();
firestore.settings({});

export const history = createBrowserHistory();

const enhancers = [];
const middleware = [thunk.withExtraArgument(getFirestore), promise];

if (process.env.NODE_ENV === 'development') {
	const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

	if (typeof devToolsExtension === 'function') {
		enhancers.push(devToolsExtension());
	}

	middleware.push(logger);
}

const createStoreWithFirebase = compose(reduxFirestore(firebase))(createStore);

const store = createStoreWithFirebase(
	rootReducer,
	compose(
		applyMiddleware(...middleware, routerMiddleware(history)),
		...enhancers,
	),
);

export const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance,
};

export default store;
