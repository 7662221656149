export const isFetching = {
	hasSuccess: false,
	hasError: false,
	isFetching: true,
};

export const withSuccess = {
	hasSuccess: true,
	hasError: false,
	isFetching: false,
};

export const withError = {
	hasSuccess: false,
	hasError: true,
	isFetching: false,
};
