import React from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Header, Info, Button } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../utils';
import Permissions from './Permissions';
import { STORAGE_URL, SCREENSHOTS_PATH, APP_URL } from '../../config';
import { downloadCSV, exportResponses } from '../../actions';

export default function FlowDetails() {
	const { flowId } = useParams();
	const dispatch = useDispatch();
	const flow = useSelector(({ firestore: { data } }) => data.flow);
	const meta = useSelector(({ firestore: { data } }) => data.flow_meta);

	useFirestoreConnect(() => [
		{ collection: 'flows', storeAs: 'flow', doc: flowId },
		{ collection: 'flows_meta', storeAs: 'flow_meta', doc: flowId },
	]);

	if (!flow) return null;
	if (!meta) return null;

	const onExportResponses = async () => {
		const table = await dispatch(exportResponses(flowId));
		downloadCSV(table, `heyflow-export-${dayjs().format('YYYY-MM-DD')}.csv`);
	};

	const dateFormat = 'DD.MM.YYYY HH:mm';
	const marginRight = 30;

	return (
		<div className="scroll">
			<Header pretext={flow?.id} title={flow?.title}>
				<div style={{ display: 'flex' }}>
					<Button
						style={{ marginRight: 30 }}
						label="Export all responses as CSV"
						onClick={onExportResponses}
					/>

					<a
						target="_blank"
						rel="noreferrer"
						href={`${APP_URL}/flow/${flowId}/build/screens/0`}>
						<Button label="Go to flow in app" />
					</a>
				</div>
			</Header>

			<div className="header-subheader">
				<img
					style={{ height: 150, border: '1px solid #eaeaea', marginRight }}
					src={`${STORAGE_URL}/${flow.id}/${SCREENSHOTS_PATH}/${flow.coverImageSrc}`}
				/>

				<Info
					style={{ marginRight }}
					label="Status"
					layout="block"
					type="status"
					value={translate(meta.status)}
					statusColor={meta.status === 'live' ? 'positive' : 'neutral'}
				/>

				<Info
					style={{ marginRight }}
					label="Responses"
					layout="block"
					type="status"
					value={meta.responses}
				/>

				<Info
					style={{ marginRight }}
					label="Created"
					layout="block"
					type="status"
					value={
						meta.created?.seconds &&
						dayjs(meta.created.seconds * 1000).format(dateFormat)
					}
				/>

				<Info
					style={{ marginRight }}
					label="Last Update"
					layout="block"
					type="status"
					value={
						meta.lastUpdate?.seconds &&
						dayjs(meta.lastUpdate.seconds * 1000).format(dateFormat)
					}
				/>

				<Info
					style={{ marginRight }}
					label="Last Published"
					layout="block"
					type="status"
					value={
						meta.lastDeployment?.seconds &&
						dayjs(meta.lastDeployment.seconds * 1000).format(dateFormat)
					}
				/>
			</div>

			<Permissions flow={flow} flowId={flowId} />
		</div>
	);
}
