import { uniqBy } from 'lodash';
import { SEARCH_USERS } from '../actions/algolia';
import { GET_USERS } from '../actions/firestore';

const initialUserState = {
	status: 'loading',
	data: [],
	err: undefined,
};

export default (state = initialUserState, action) => {
	state.actionType = action.type;

	switch (action.type) {
		case GET_USERS:
			return {
				...state,
				status: action.status,
				data: action.data,
				err: action.error,
			};

		case SEARCH_USERS:
			return {
				...state,
				status: action.status,
				data: uniqBy([...(state.data ?? []), ...(action.data ?? [])], 'id'),
				err: action.error,
			};

		default:
			return state;
	}
};
