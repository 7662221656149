import React from 'react';
import UserDetails from './UserID';
import { Route, Switch } from 'react-router-dom';
import UserList from './UserList';

export default function Users() {
	return (
		<Switch>
			<Route exact path="/users">
				<UserList />
			</Route>
			<Route path="/users/:userId">
				<UserDetails />
			</Route>
		</Switch>
	);
}
