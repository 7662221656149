import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { User } from 'src/types';
import { SectionItem } from '../common';

const fields = {
	upload: 'exists',
};

interface Blocks {
	readonly user: User;
	readonly userId: string;
}

export const Blocks: React.FC<Blocks> = ({ user, userId }) => {
	const firestore = useFirestore();

	function onUpdateFeature({ prop, value }: { prop: string; value: any }) {
		const { blocksDenylist } = user.features;
		const denyList = [...(blocksDenylist || [])];

		const add = [...denyList, prop];
		if (value === false) {
			denyList.splice(denyList.indexOf(prop), 1);
		}

		const set = value === true ? add : denyList;

		firestore.update(
			{
				collection: 'users',
				doc: userId,
			},
			{
				features: {
					...user.features,
					blocksDenylist: set,
				},
			},
		);
	}

	return (
		<SectionItem
			data={user?.features?.blocksDenylist}
			fields={fields}
			onUpdate={onUpdateFeature}
			title="Blocks Deny List"
			canEdit={true}
			canDelete={true}
		/>
	);
};
