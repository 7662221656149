import axios from 'axios';
import { rrfProps } from '../store';

export const getAuthToken = async state =>
	state.firebase.auth.stsTokenManager.expirationTime <= Date.now()
		? rrfProps.firebase.auth().currentUser.getIdToken(true)
		: state.firebase.auth.stsTokenManager.accessToken;

export default (state, options) => {
	const axiosInstance = axios.create(options);
	axiosInstance.interceptors.request.use(async config => {
		try {
			const token = await getAuthToken(state);
			config.headers.Authorization = token ? `Bearer ${token}` : '';
			return config;
		} catch (err) {
			console.error(err);
		}
	});

	return axiosInstance;
};
