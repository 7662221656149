import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store, { rrfProps } from './store';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';

import App from './components/app';
import './styles/index.css';

import * as serviceWorker from './serviceWorker';

const target = document.querySelector('#root');

render(
	<Provider store={store}>
		<BrowserRouter>
			<ReactReduxFirebaseProvider {...rrfProps}>
				<App />
			</ReactReduxFirebaseProvider>
		</BrowserRouter>
	</Provider>,
	target,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
