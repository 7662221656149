import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './login';
import Flows from './flows';
import Users from './users';
import { SideBar } from './common';

function ProtectedRoute({ children, ...rest }) {
	const { isLoaded, isEmpty } = useSelector(({ firebase: { auth } }) => auth);

	return (
		<Route
			{...rest}
			render={() => (isLoaded && !isEmpty ? children : <Redirect to="/" />)}
		/>
	);
}

export default function App() {
	const { isLoaded, isEmpty } = useSelector(({ firebase: { auth } }) => auth);

	if (!isLoaded) return <div>Loading…</div>;

	return (
		<Switch>
			<Route exact path="/">
				{isLoaded && isEmpty ? <Login /> : <Redirect to="/flows" />}
			</Route>
			<ProtectedRoute path="/flows">
				<div className="flex">
					<SideBar />
					<Flows />
				</div>
			</ProtectedRoute>
			<ProtectedRoute path="/users">
				<div className="flex">
					<SideBar />
					<Users />
				</div>
			</ProtectedRoute>
			<Route path="*">
				<span>404: Not found</span>
				<a href="/flows">Go home</a>
			</Route>
		</Switch>
	);
}
