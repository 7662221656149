import authios from '../modules/authios';
import { API } from '../config';

export const GET_BILLING = 'GET_BILLING';
export const GET_BILLING_SUCCESS = 'GET_BILLING_SUCCESS';
export const GET_BILLING_FAIL = 'GET_BILLING_FAIL';

export const getBillingInfo = userId => {
	return (dispatch, getState) => {
		dispatch({ type: GET_BILLING });

		return authios(getState())
			.get(`${API}/tower/user/${userId}/billing`)
			.then(res => {
				dispatch({
					type: GET_BILLING_SUCCESS,
					payload: res.data,
				});

				return true;
			})
			.catch(err => {
				dispatch({
					type: GET_BILLING_FAIL,
					payload: err,
				});

				throw err;
			});
	};
};

export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAIL = 'GET_SUBSCRIPTION_FAIL';

export const getSubscriptionInfo = userId => {
	return (dispatch, getState) => {
		dispatch({ type: GET_SUBSCRIPTION });

		return authios(getState())
			.get(`${API}/tower/user/${userId}/subscription`)
			.then(res => {
				dispatch({
					type: GET_SUBSCRIPTION_SUCCESS,
					payload: res.data,
				});

				return true;
			})
			.catch(err => {
				dispatch({
					type: GET_SUBSCRIPTION_FAIL,
					payload: err,
				});

				throw err;
			});
	};
};

export const UPDATE_EMAIL_ADDRESS = 'UPDATE_EMAIL_ADDRESS';
export const UPDATE_EMAIL_ADDRESS_SUCCESS = 'UPDATE_EMAIL_ADDRESS_SUCCESS';
export const UPDATE_EMAIL_ADDRESS_FAIL = 'UPDATE_EMAIL_ADDRESS_FAIL';

export const updateEmailAddress = (userId, email) => {
	return (dispatch, getState) => {
		dispatch({ type: UPDATE_EMAIL_ADDRESS });

		return authios(getState())
			.post(`${API}/tower/user/${userId}/email`, { email })
			.then(res => {
				dispatch({
					type: UPDATE_EMAIL_ADDRESS_SUCCESS,
					payload: res.data,
				});

				return true;
			})
			.catch(err => {
				dispatch({
					type: UPDATE_EMAIL_ADDRESS_FAIL,
					payload: err,
				});

				throw err;
			});
	};
};

export const SYNC_USER = 'SYNC_USER';
export const SYNC_USER_SUCCESS = 'SYNC_USER_SUCCESS';
export const SYNC_USER_FAIL = 'SYNC_USER_FAIL';

export const syncUser = (userId, email) => {
	return (dispatch, getState) => {
		dispatch({ type: SYNC_USER });

		return authios(getState())
			.post(`${API}/tower/user/${userId}/sync`)
			.then(res => {
				dispatch({
					type: SYNC_USER_SUCCESS,
					payload: res.data,
				});

				return true;
			})
			.catch(err => {
				dispatch({
					type: SYNC_USER_FAIL,
					payload: err,
				});

				throw err;
			});
	};
};

export const VERIFY_USER = 'VERIFY_USER';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAIL = 'VERIFY_USER_FAIL';

export const verifyUser = userId => {
	return (dispatch, getState) => {
		dispatch({ type: VERIFY_USER });

		return authios(getState())
			.post(`${API}/tower/user/${userId}/verify`)
			.then(res => {
				dispatch({
					type: VERIFY_USER_SUCCESS,
					payload: res.data,
				});
			})
			.catch(err => {
				dispatch({
					type: VERIFY_USER_FAIL,
					payload: err,
				});
			});
	};
};
