import React from 'react';

export default function Section({ title, children }) {
	return (
		<div className="section">
			<h4 className="section-title">{title}</h4>
			<div className="section-inner">{children}</div>
		</div>
	);
}
