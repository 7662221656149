import React, { useState } from 'react';
import { useFirebase } from 'react-redux-firebase';
import logo from '../../assets/niro-tower-logo.png';

export default function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const firebase = useFirebase();

	const login = async () => {
		await firebase.login({
			email,
			password,
		});
	};

	const onLogin = e => {
		if (e) e.preventDefault();
		login();
	};

	return (
		<div className="login">
			<div>
				<img alt="Niro Tower Logo" src={logo} />
			</div>
			<form onSubmit={onLogin}>
				<h2>Login</h2>
				<div className="input-field">
					<label htmlFor="lname">Email</label>
					<input type="text" onChange={e => setEmail(e.target.value)} />
				</div>
				<div className="input-field">
					<label htmlFor="lname">Password</label>
					<input type="password" onChange={e => setPassword(e.target.value)} />
				</div>
				<div>
					<input type="submit" />
				</div>
			</form>
		</div>
	);
}
