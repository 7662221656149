import React from 'react';

export default function Header({ pretext, title, children }) {
	return (
		<div className="header">
			<div>
				<span className="header-pretext">{pretext}</span>
				<div className="header-title">
					<h1>{title}</h1>
				</div>
			</div>
			{children}
		</div>
	);
}
