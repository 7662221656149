import algoliasearch from 'algoliasearch';
import {
	ALGOLIA_APP_ID,
	ALGOLIA_SEARCH_KEY,
	ALGOLIA_ENV_PREFIX,
} from '../config';

export const SEARCH_FLOWS = 'SEARCH_FLOWS';
export const SEARCH_USERS = 'SEARCH_USERS';

const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

export const searchFlows = query => {
	const indexName = 'flows';

	return async dispatch => {
		dispatch({ type: SEARCH_FLOWS, status: 'fetching' });

		try {
			const searchIndex = `${ALGOLIA_ENV_PREFIX}${indexName}`;
			const index = client.initIndex(searchIndex);
			const { hits } = await index.search(query);
			dispatch({ type: SEARCH_FLOWS, data: hits, status: 'success' });
		} catch (err) {
			console.error(err);
			dispatch({ type: SEARCH_FLOWS, status: 'error' });
		}
	};
};

export const searchUsers = query => {
	const indexName = 'users';

	return async dispatch => {
		dispatch({ type: SEARCH_USERS, status: 'fetching' });

		try {
			const searchIndex = `${ALGOLIA_ENV_PREFIX}${indexName}`;
			const index = client.initIndex(searchIndex);
			const { hits } = await index.search(query);
			dispatch({ type: SEARCH_USERS, data: hits, status: 'success' });
		} catch (err) {
			console.error(err);
			dispatch({ type: SEARCH_USERS, status: 'error' });
		}
	};
};
