import React from 'react';
import classnames from 'classnames';
import { Switch } from './index';
import { InputNumber } from 'antd';

export default function Info({
	style,
	layout,
	label,
	type,
	href,
	onChange,
	value,
	disabled,
	statusColor,
}) {
	return (
		<div
			style={style}
			className={classnames('info-field', {
				'is-block': layout === 'block',
				'is-inline': layout === 'inline',
			})}>
			<div className="info-field-label">
				<span>{label}</span>
			</div>
			<div className="info-field-value">
				{type === 'switch' && (
					<Switch checked={value} onChange={onChange} disabled={disabled} />
				)}

				{type === 'link' && (
					<a
						target="_blank"
						rel="noopener noreferrer"
						className="text-link"
						href={href}>
						<span>{value}</span>
					</a>
				)}

				{type === 'input' && (
					<InputNumber value={value} onChange={onChange} disabled={disabled} />
				)}

				{type === 'status' && (
					<div>
						{statusColor && (
							<span
								className={classnames('info-status', {
									'green-2': statusColor === 'positive',
									'gray-600': statusColor === 'neutral',
								})}>
								<ion-icon name="ellipse"></ion-icon>
							</span>
						)}
						<span>{value}</span>
					</div>
				)}

				{type === 'text' && <span>{value}</span>}
			</div>
		</div>
	);
}
