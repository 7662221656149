import React from 'react';
import { SectionItem } from '../common';

const fields = {
	displayName: 'input',
	email: 'input',
	lang: 'input',
	stripeCustomerID: 'link',
	vatID: 'input',
	emailVerified: 'boolean',
};

export default function Profile({ user }) {
	return <SectionItem data={user} fields={fields} title="Profile" />;
}

//For future reference if Profile becomes editable
//export default function General({ user, userId }) {
//const firestore = useFirestore();

// function onUpdateFeature({ prop, value }) {
//     firestore.update(
//         {
//             collection: 'users',
//             doc: userId,
//         },
//         {
//             user: {
//                 ...user,
//                 [prop]: value,
//             },
//         },
//     );
// }
