import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import user from './user';
import users from './users';
import flow from './flow';
import flows from './flows';

export default combineReducers({
	firebase: firebaseReducer,
	firestore: firestoreReducer,
	user,
	users,
	flow,
	flows,
});
