import React from 'react';
import FlowList from './FlowList';
import FlowDetails from './FlowID';
import { Route, Switch } from 'react-router-dom';

export default function Flows() {
	return (
		<Switch>
			<Route exact path="/flows">
				<FlowList />
			</Route>
			<Route path="/flows/:flowId">
				<FlowDetails />
			</Route>
		</Switch>
	);
}
