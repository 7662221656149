import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { User } from 'src/types';
import { SectionItem } from '../common';
import swal from 'sweetalert';

const fields = {
	background: 'exists',
	'border-color': 'exists',
	'border-radius': 'exists',
	primary: 'exists',
	secondary: 'exists',
	'font-family': 'exists',
	'font-family-secondary': 'exists',
	'font-color': 'exists',
	'font-size': 'exists',
	'p-color': 'exists',
	'p-font-size': 'exists',
	'p-font-weight': 'exists',
	'p-line-height': 'exists',
	'label-font-size': 'exists',
	'label-color': 'exists',
	'h1-font-family': 'exists',
	'h1-font-size': 'exists',
	'h1-font-weight': 'exists',
	'h1-color': 'exists',
	'h1-line-height': 'exists',
	'h1-letter-spacing': 'exists',
	'h2-font-family': 'exists',
	'h2-font-size': 'exists',
	'h2-font-weight': 'exists',
	'h2-color': 'exists',
	'h2-line-height': 'exists',
	'h2-letter-spacing': 'exists',
	'header-position': 'exists',
	'header-background': 'exists',
	'header-border-bottom': 'exists',
	'header-border-bottom-color': 'exists',
	'header-box-shadow': 'exists',
	'header-min-height': 'exists',
	'header-min-height-mobile': 'exists',
	'footer-background': 'exists',
	'footer-border-top': 'exists',
	'footer-border-top-color': 'exists',
	'footer-box-shadow': 'exists',
};

interface DesignVariables {
	readonly user: User;
	readonly userId: string;
}

export const DesignVariables: React.FC<DesignVariables> = ({
	user,
	userId,
}) => {
	const firestore = useFirestore();

	async function onDeleteList() {
		try {
			const confirmed = await swal({
				text: 'Are you sure you want to delete this list?',
				icon: 'warning',
				buttons: ['Cancel', 'Yes'],
				dangerMode: true,
			});

			if (!confirmed) return;

			firestore.update(
				{
					collection: 'users',
					doc: userId,
				},
				{
					features: {
						...user.features,
						designVariablesAllowlist: false,
					},
				},
			);
		} catch (err) {
			console.error(err);
			swal('Oh no!', 'Something went wrong :(');
		}
	}

	function onUpdateFeature({ prop, value }: { prop: string; value: any }) {
		const { designVariablesAllowlist } = user.features;
		const denyList = [...(designVariablesAllowlist || [])];

		const add = [...denyList, prop];
		if (value === false) {
			denyList.splice(denyList.indexOf(prop), 1);
		}

		const set = value === true ? add : denyList;

		firestore.update(
			{
				collection: 'users',
				doc: userId,
			},
			{
				features: {
					...user.features,
					blocksDenylist: set,
				},
			},
		);
	}

	return (
		<SectionItem
			data={user?.features?.designVariablesAllowlist}
			fields={fields}
			onUpdate={onUpdateFeature}
			onDelete={onDeleteList}
			title="Design Variables Allow List"
			canEdit={true}
			canDelete={true}
		/>
	);
};
