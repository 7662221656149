import React, { Fragment, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Table } from '../common';
import { translate } from '../utils';
import { getUsers } from '../../actions/firestore';
import { searchUsers } from '../../actions/algolia';

const transformUser = user => {
	const { id, email, status, planID } = user;
	return {
		href: `/users/${id}`,
		values: [
			{ label: email },
			{
				label: translate(status),
				statusColor:
					status === 'active' || status === 'active-no-stripe'
						? 'positive'
						: 'neutral',
			},
			{ label: translate(planID) },
		],
	};
};

export default function UserList() {
	const dispatch = useDispatch();
	const users = useSelector(state => state.users.data);

	useEffect(() => {
		dispatch(getUsers());
	}, [dispatch]);

	const searchForUser = useCallback(
		debounce(searchQuery => {
			dispatch(searchUsers(searchQuery));
		}, 500),
	);

	return (
		<Fragment>
			<div className="scroll">
				<Table
					onSearch={searchForUser}
					hasSearchBar={true}
					title="Users"
					labels={['Email', 'Status', 'Plan']}
					rows={users ? users.map(transformUser) : []}
				/>
			</div>
		</Fragment>
	);
}
