export const GET_FLOW = 'GET_FLOW';
export const GET_FLOWS = 'GET_FLOWS';
export const GET_USER = 'GET_USER';
export const GET_USERS = 'GET_USERS';

export const getFlows = async () => {
	return async (dispatch, _, getFirestore) => {
		const firestore = getFirestore();

		dispatch({ type: GET_FLOWS, status: 'fetching', data: undefined });

		try {
			const res = await firestore.collection('flows').limit(20).get();
			const flows = res.docs.map(doc => ({ ...doc.data(), id: doc.id }));

			dispatch({
				type: GET_FLOWS,
				status: 'success',
				data: flows,
				error: undefined,
			});
		} catch (err) {
			dispatch({
				type: GET_FLOWS,
				status: 'error',
				error: err,
			});
		}
	};
};

export const getFlow = async flowId => {
	return async (dispatch, _, getFirestore) => {
		const firestore = getFirestore();

		dispatch({ type: GET_FLOW, status: 'fetching' });

		try {
			const res = await firestore.collection('flows').doc(flowId).get();
			const flow = res.data();

			dispatch({
				type: GET_FLOW,
				status: 'success',
				data: { ...flow, id: flowId },
				error: undefined,
			});
		} catch (err) {
			dispatch({
				type: GET_FLOW,
				status: 'error',
				error: err,
			});
		}
	};
};

export const getUsers = () => {
	return async (dispatch, _, getFirestore) => {
		const firestore = getFirestore();

		dispatch({ type: GET_USERS, status: 'fetching', data: undefined });

		try {
			const res = await firestore.collection('users').limit(20).get();
			const users = res.docs.map(doc => ({ ...doc.data(), id: doc.id }));

			dispatch({
				type: GET_USERS,
				status: 'success',
				data: users,
				error: undefined,
			});
		} catch (err) {
			dispatch({
				type: GET_USERS,
				status: 'error',
				error: err,
			});
		}
	};
};

export const getUser = async userId => {
	return async (dispatch, _, getFirestore) => {
		const firestore = getFirestore();

		dispatch({ type: GET_USER, status: 'fetching' });

		try {
			const res = await firestore.collection('users').doc(userId).get();
			const user = res.data();

			dispatch({
				type: GET_USER,
				status: 'success',
				data: { ...user, id: userId },
				error: undefined,
			});
		} catch (err) {
			dispatch({
				type: GET_USER,
				status: 'error',
				error: err,
			});
		}
	};
};
