import authios from '../modules/authios';
import { API } from '../config';

export const EXPORT_RESPONSES = 'EXPORT_RESPONSES';

export const downloadCSV = (data, filename) => {
	const dataStr = 'data:text/csv;charset=utf-8,' + data;
	const downloadAnchorNode = document.createElement('a');

	const encodedUri = encodeURI(dataStr);
	downloadAnchorNode.setAttribute('href', encodedUri);
	downloadAnchorNode.setAttribute('download', filename);
	document.body.appendChild(downloadAnchorNode);
	downloadAnchorNode.click();
	downloadAnchorNode.remove();
};

export const exportResponses = flowId => {
	return (dispatch, getState) => {
		dispatch({ type: EXPORT_RESPONSES, status: 'loading' });

		return authios(getState())
			.get(`${API}/tower/flow/${flowId}/responses/export`)
			.then(res => {
				dispatch({
					type: EXPORT_RESPONSES,
					status: 'success',
				});

				return res.data;
			})
			.catch(err => {
				dispatch({
					type: EXPORT_RESPONSES,
					status: 'error',
					payload: err,
				});

				throw err;
			});
	};
};
