import React, { useState } from 'react';
import { Info } from '.';
import { translate } from '../utils';

interface OnUpdate {
	readonly value: string | boolean;
	readonly prop: string;
}

interface SectionItem {
	readonly data: any;
	readonly fields: any;
	onUpdate: (arg0: OnUpdate) => void;
	readonly title: string;
	readonly canEdit?: boolean;
	readonly canDelete?: boolean;
	onDelete?: () => void;
}

export const SectionItem: React.FC<SectionItem> = ({
	data,
	fields,
	onUpdate,
	title,
	canEdit,
	canDelete,
	onDelete,
}) => {
	const [editMode, setEditMode] = useState(true);

	function getField(prop: string): any {
		const defaults = {
			label: translate(prop),
			value: data[prop],
			onChange: (value: any) => onUpdate({ value, prop }),
			layout: 'inline',
		};

		switch (fields[prop]) {
			case 'input': {
				return {
					...defaults,
					type: editMode === true ? 'text' : 'input',
				};
			}

			case 'boolean': {
				return {
					...defaults,
					type: 'switch',
					disabled: editMode !== false,
				};
			}

			case 'exists': {
				return {
					...defaults,
					type: 'switch',
					value: data.indexOf(prop) > -1 ? true : false,
					disabled: editMode !== false,
				};
			}

			case 'link': {
				return {
					...defaults,
					href: `https://dashboard.stripe.com/customers/${data[prop]}`,
					type: 'link',
				};
			}

			default:
				console.error(`No field defined for ${prop}`);
				return {};
		}
	}

	return (
		<div className="section-col">
			<div className="section-col-header">
				<span className="section-col-label">{title}</span>
				{data && (
					<>
						{canEdit && (
							<div
								className="button"
								onClick={() => setEditMode(editMode === true ? false : true)}>
								{editMode === true ? 'Edit' : 'Done'}
							</div>
						)}

						{canDelete && (
							<div
								style={{ marginLeft: 10 }}
								className="button"
								onClick={onDelete}>
								Delete
							</div>
						)}
					</>
				)}
			</div>

			{data ? (
				<div className="info-container-col">
					{Object.keys(fields)
						.map(getField)
						.map(field => (
							<Info key={field.id} {...field} />
						))}
				</div>
			) : (
				<div>Empty</div>
			)}
		</div>
	);
};
