import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import swal from '@sweetalert/with-react';
import { Table, Header, Info, Section, Button } from '../common';
import { translate } from '../utils';
import Profile from './Profile';
import General from './General';
import Integrations from './Integrations';
import Modi from './Modi';
import {
	getBillingInfo,
	getSubscriptionInfo,
	updateEmailAddress,
	syncUser,
	verifyUser,
} from '../../actions/user';

import { Blocks } from './Blocks';
import { DesignVariables } from './DesignVariables';

function getInfoFields({ user }) {
	const { status, planID } = user;
	return [
		{
			label: 'Status',
			value: status ? translate(status) : 'Undefined',
			type: 'status',
			positiveIndicator: status === 'active-no-stripe' || status === 'active',
			negativeIndicator:
				!status || status !== 'active-no-stripe' || status !== 'active',
		},
		{
			label: 'Plan',
			value: planID ? translate(planID) : 'No plan',
			type: 'text',
		},
	];
}

export default function UserDetails() {
	const { userId } = useParams();
	const dispatch = useDispatch();
	const flows = useSelector(({ firestore: { data } }) => data.flows);
	const user = useSelector(({ firestore: { data } }) => data.user);

	useFirestoreConnect(() => [
		{ collection: 'users', storeAs: 'user', doc: userId },
	]);

	useFirestoreConnect(() => [
		{ collection: 'flows', where: [`permissions.${userId}`, '==', 'owner'] },
	]);

	useEffect(() => {
		dispatch(getBillingInfo(userId));
		dispatch(getSubscriptionInfo(userId));
	}, [userId, dispatch]);

	function onUpdateEmail() {
		swal({
			text: 'Update email address',
			content: {
				element: 'input',
				attributes: {
					placeholder: 'New email address',
				},
			},
			button: {
				text: 'Update email address',
			},
		})
			.then(email => {
				dispatch(updateEmailAddress(userId, email));
			})
			.catch(err => {
				if (err) {
					swal('Oh no!', 'Something went wrong :(');
				} else {
					swal.stopLoading();
					swal.close();
				}
			});
	}

	function onSyncUser() {
		swal({
			text: 'Sync with Stripe',
			content: (
				<span>
					Are you sure? The user’s profile information including its features
					will be overwritten.
				</span>
			),
			buttons: ['Cancel', 'Yes, sync and overwrite'],
		})
			.then(check => {
				if (!check) return;
				dispatch(syncUser(userId));
			})
			.catch(err => {
				console.error(err);

				if (err) {
					swal('Oh no!', 'Something went wrong :(');
				} else {
					swal.stopLoading();
					swal.close();
				}
			});
	}

	function onVerifyUser() {
		dispatch(verifyUser(userId));
	}

	return (
		<div className="scroll">
			<Header pretext="User" title={user?.email}>
				<div style={{ display: 'flex' }}>
					{!user?.emailVerified && (
						<Button
							style={{ marginRight: 20 }}
							label="Verify user's email address"
							onClick={onVerifyUser}
						/>
					)}
					<Button
						style={{ marginRight: 20 }}
						label="Sync user with Stripe"
						onClick={onSyncUser}
					/>
					<Button label="Update email" onClick={onUpdateEmail} />
				</div>
			</Header>

			{user && (
				<div className="info-container-row">
					{getInfoFields({ user }).map((field, i) => (
						<Info key={i} {...field} layout="is-inline" />
					))}
				</div>
			)}

			<Section title="Details">
				<Profile user={user} />
			</Section>

			<Section title="Features">
				<General user={user} userId={userId} />
				<Integrations user={user} userId={userId} />
				<Modi user={user} userId={userId} />
			</Section>

			<Section title="Features">
				<DesignVariables user={user} userId={userId} />
				<Blocks user={user} userId={userId} />
			</Section>

			<div style={{ marginBottom: 80 }}>
				<Table
					hasSearchBar={false}
					title="Flows"
					labels={['Title', 'ID', 'Status']}
					rows={
						flows
							? Object.keys(flows).map(docId => {
									const { title, id, status } = flows[docId];
									return {
										href: `/flows/${docId}`,
										values: [
											{ label: title },
											{ label: id },
											{
												label: translate(status),
												statusColor: status === 'live' ? 'positive' : 'neutral',
											},
										],
									};
							  })
							: []
					}
				/>
			</div>
		</div>
	);
}
