import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { SectionItem } from '../common';

const fields = {
	asana: 'boolean',
	fbPixel: 'boolean',
	googleAnalytics: 'boolean',
	googleAnalytics4: 'boolean',
	googleAppEngine: 'boolean',
	googleSheets: 'boolean',
	googleTagManager: 'boolean',
	pipedrive: 'boolean',
	ses: 'boolean',
	slack: 'boolean',
	webhook: 'boolean',
	hubspot: 'boolean',
	zapierV2: 'boolean',
	legalMonster: 'boolean',
	airtable: 'boolean',
	facebookDomainVerification: 'boolean',
};

export default function Integrations({ user, userId }) {
	const firestore = useFirestore();

	function onUpdateFeature({ prop, value }) {
		const { integrations } = user.features;

		firestore.update(
			{
				collection: 'users',
				doc: userId,
			},
			{
				features: {
					...user.features,
					integrations: {
						...integrations,
						[prop]: value,
					},
				},
			},
		);
	}

	return (
		<SectionItem
			data={user?.features?.integrations || {}}
			fields={fields}
			onUpdate={onUpdateFeature}
			title="Integrations"
			canEdit={true}
		/>
	);
}
