import {
	GET_BILLING,
	GET_BILLING_SUCCESS,
	GET_BILLING_FAIL,
	GET_SUBSCRIPTION,
	GET_SUBSCRIPTION_SUCCESS,
	GET_SUBSCRIPTION_FAIL,
} from '../actions/user';
import { GET_USER } from '../actions/firestore';
import { isFetching, withSuccess, withError } from '../utils';

export default (state = {}, action) => {
	state.actionType = action.type;

	switch (action.type) {
		case GET_USER:
			return {
				...state,
				data: action.data,
				status: action.status,
				err: action.error,
			};

		case GET_BILLING:
			return {
				...state,
				...isFetching,
			};

		case GET_BILLING_SUCCESS:
			return {
				...state,
				billing: action.payload,
				...withSuccess,
			};

		case GET_BILLING_FAIL:
			return {
				...state,
				billing: null,
				error: action.payload,
				...withError,
			};

		case GET_SUBSCRIPTION:
			return {
				...state,
				...isFetching,
			};

		case GET_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				subscription: action.payload,
				...withSuccess,
			};

		case GET_SUBSCRIPTION_FAIL:
			return {
				...state,
				subscription: null,
				error: action.payload,
				...withError,
			};

		default:
			return state;
	}
};
