export const API =
	process.env.NODE_ENV === 'production'
		? process.env.REACT_APP_ENV === 'staging'
			? 'https://zenflow-builder-stage.appspot.com'
			: 'https://zenflow-builder.appspot.com'
		: 'http://localhost:8181';

export const ALGOLIA_APP_ID = 'EQMINW4NVH';
export const ALGOLIA_SEARCH_KEY = 'c001768481566f11a6755907a74293b1';
export const ALGOLIA_ENV_PREFIX =
	process.env.NODE_ENV === 'production' ||
	process.env.REACT_APP_FIREBASE_MODE === 'production'
		? 'prod_'
		: 'dev_';

export const STORAGE_URL =
	process.env.NODE_ENV === 'production' ||
	process.env.REACT_APP_FIREBASE_MODE === 'production'
		? 'https://storage.googleapis.com/builder.zenflow.de'
		: 'https://storage.googleapis.com/staging.niro.co';

export const SCREENSHOTS_PATH = 'www/assets/screenshots';

export const APP_URL =
	process.env.NODE_ENV === 'production'
		? 'https://go.heyflow.app'
		: 'http://localhost:3000';
